// import SanityBlockContent from "@sanity/block-content-to-react"
import React from "react"
// import { HeartIcon } from "@heroicons/react/outline"
// import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { BoatDescCard, TemplateHeader } from "../components/template"
import Seo from "../components/seo"
export default function Page({ pageContext: { data } }) {
  // console.log(data)
  return (
    <Layout>
      <Seo
        title={data.title || data.name}
        // schemaMarkup={schema}
        // description={property.highlight}
      />
      <TemplateHeader
        name={data.name}
        image={data.image.asset && data.image.asset.gatsbyImageData}
        imgSrc={data.image.asset.gatsbyImageData.images.fallback.src}
        length={data.length}
        capacity={data.capacity}
        link="/actions/boat/request?name="
      />
      <BoatDescCard data={data} className="pt-12" />
      {/* <Booking /> */}
    </Layout>
  )
}
